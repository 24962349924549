import { Flex } from '@mantine/core'

import { useScreenSize } from '~/hooks'

import { ThatchLink } from './ThatchLink'
import { SvgIcon, SvgIconType } from './image/SvgIcon'
import { Typography } from './text/Typography'

const ThatchGPTButton = ({ inline = false }: { inline?: boolean }) => {
  const { isMobileScreen } = useScreenSize()

  return (
    <ThatchLink
      sx={{
        position: inline ? 'relative' : 'fixed',
        bottom: inline ? undefined : (isMobileScreen ? 16 : 32),
        right: inline ? undefined : (isMobileScreen ? 16 : 32),
        background: 'linear-gradient(93deg, #D05BB9 31.26%, #D16EDA 70.71%)',
        borderRadius: 500,
        boxShadow: inline ? '' : '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        display: 'block',
      }}
      to="/thatchgpt"
      isOpenInNewWindow
      underline={false}
    >
      <Flex
        p="10px 16px"
        align="center"
        gap={6}
      >
        <SvgIcon type={SvgIconType.TRY_THATCH_BUTTON_ICON} />
        <Typography
          color="appWhite.0"
          variant="button_medium"
          sx={{ lineHeight: '120%', fontSize: '20px !important' }}
        >
          Ask ThatchGPT
        </Typography>
      </Flex>
    </ThatchLink>
  )
}

export default ThatchGPTButton
